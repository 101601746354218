import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [2,4];

export const dictionary = {
		"/": [~5],
		"/[region=region]": [~7],
		"/[region=region]/[lang=lang]/(app)/account": [~10,[2,4],[3]],
		"/[region=region]/[lang=lang]/(app)/account/communication": [~11,[2,4],[3]],
		"/[region=region]/[lang=lang]/(app)/account/delete": [~12,[2,4],[3]],
		"/[region=region]/[lang=lang]/(app)/account/orders": [~13,[2,4],[3]],
		"/[region=region]/[lang=lang]/(app)/account/password": [~14,[2,4],[3]],
		"/[region=region]/[lang=lang]/(app)/account/profile": [~15,[2,4],[3]],
		"/[region=region]/[lang=lang]/(app)/account/shipping": [~16,[2,4],[3]],
		"/[region=region]/[lang=lang]/(app)/discount/[code]": [17,[2],[3]],
		"/[region=region]/[lang=lang]/(app)/discount/[code]/[...path]": [18,[2],[3]],
		"/[region=region]/[lang=lang]/lp/[...path]": [~23],
		"/[region=region]/[lang=lang]/(app)/products/[handle]": [~19,[2],[3]],
		"/[region=region]/[lang=lang]/(app)/search": [20,[2],[3]],
		"/[region=region]/[lang=lang]/(app)/wishlist": [21,[2],[3]],
		"/[region=region]/[lang=lang]/(app)/wishlist/[[user]]/[products]": [~22,[2],[3]],
		"/[region=region]/[lang=lang]/(app)/[...path]": [~9,[2],[3]],
		"/[region=region]/[...path]": [~8],
		"/[...path]": [~6]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';